import {
  defineNuxtRouteMiddleware,
  useRoute,
  navigateTo,
  useCookie
} from '#app'

export default defineNuxtRouteMiddleware(to => {
  // if (import.meta.client) { return }
  // const route = to
  // console.log('to', to)
  // const ibisSSOToken = route.query.ibisSSOToken as string | undefined
  // const referer = document.referrer || ''
  // console.log('ibisSSOToken', ibisSSOToken)
  // console.log('referer', referer)
  // if (ibisSSOToken && referer.startsWith('https://auth.ibis.loc')) {
  //   const tokenCookie = useCookie('ibisSSOToken', {
  //     httpOnly: false,
  //     secure: true,
  //     sameSite: 'lax',
  //     maxAge: 10 * 360 * 86400 // 10 years
  //   })
  //   tokenCookie.value = ibisSSOToken
  //   const newQuery = { ...route.query }
  //   delete newQuery.ibisSSOToken
  //   console.log('newQuery', newQuery)
  //   return navigateTo({ path: route.path, query: newQuery }, { replace: true })
  // }
})
